<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    width="62.6605mm"
    height="63.9174mm"
    version="1.1"
    style="
      shape-rendering: geometricPrecision;
      text-rendering: geometricPrecision;
      image-rendering: optimizeQuality;
      fill-rule: evenodd;
      clip-rule: evenodd;
    "
    viewBox="0 0 6266.06 6391.75"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    @click="$emit('click')"
  >
    <g id="Camada_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer" />
      <g id="_2308990843968">
        <path
          class="fil0"
          d="M1840.64 4029.11l0.48 0.01c9.67,0.6 19.4,0.94 29.22,0.94 19.39,0 38.49,-1.2 57.25,-3.5 290.02,-22.08 860.52,-172.87 1179.44,-443.24 763.33,-647.12 904.82,-1564.29 610.45,-2309.33 -64.78,-178.63 -158.97,-349.28 -295.45,-515.44 -6.75,-8.23 -13.65,-16.11 -20.47,-24.2 -101.41,-124.56 -218.23,-237.61 -348.92,-335.87 -979.68,-754.79 -2316.25,-406.79 -2865.53,786.02 -269.8,586 -168.46,1740.87 -168.46,2478.74 0,894.67 0,1789.28 0,2683.95 222.58,45.31 2569.27,15.37 3002.86,15.37 394.71,0 892.48,33.45 1254.66,26.82l0 2.37c14.3,-1.04 28.55,-2.16 42.76,-3.34l0.24 -0.01 0 -0.01c447.18,-37.43 849.67,-147.56 1193.7,-439.19 1402.53,-1189.03 706.24,-3289.86 -937.38,-3558.98 -56.16,-24.25 -118.22,-37.74 -183.49,-37.74 -252.16,0 -456.55,200.82 -456.55,448.54 0,196.84 129.04,364.03 308.55,424.44l0 0.01 0.05 0.01c37.77,12.71 77.81,20.66 119.32,23.19 452.3,99.93 940.12,353.03 1010.78,1000.25 67.62,619.49 -334.51,1065.38 -775.9,1206.89 -62.3,19.99 -174.32,35.41 -322.08,47.21l0 9.39c-969.15,42.75 -2868.54,-17.49 -3384.54,-17.49 -28.81,-676.22 -3.97,-1406.22 -3.97,-2088.28 0,-560.19 -92.49,-1520.74 147.19,-1954.18 226.76,-410.04 610.14,-599.44 977.25,-575.55l-0.03 4.57c18.14,-0.24 36.09,-0.03 53.92,0.56 426.1,52.29 818.49,394.58 902.31,1015.74l1.45 0c59.68,611.8 -339.47,1051.76 -777.34,1192.15 -57.33,18.4 -156.78,32.93 -287.44,44.32 -11.43,-0.84 -22.99,-1.28 -34.64,-1.28 -252.15,0 -456.56,200.82 -456.56,448.54 0,237.91 188.55,432.53 426.86,447.57l0 0.02z"
        />
        <ellipse
          class="fil1"
          cx="4670.45"
          cy="1582.4"
          rx="549.33"
          ry="539.69"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "WhiteLogo",
};
</script>

<style scoped>
* {
  width: 100%;
  height: auto;
}
.fil0 {
  fill: #fefefe;
}
.fil1 {
  fill: #19e06f;
}
</style>
