<template>
  <div>
    <v-navigation-drawer
      v-if="drawer"
      id="menu"
      v-model="drawer"
      app
      width="180"
      color="secondary"
      class="text-center no-print"
      style="min-width: 100px; overflow: hidden"
      stateless
    >
      <white-logo
        style="width: 60px"
        class="pt-5 pb-3 pb-0 cursor-pointer"
        @click="redirect"
      />
      <v-row justify="center" class="mb-1">
        <facebook-icon
          style="width: 25px; height: 30px"
          class="mr-2 cursor-pointer"
          @click="openFacebook"
        />
        <youtube-icon
          style="width: 25px; height: 30px"
          class="mr-2 cursor-pointer"
          @click="openYoutube"
        />
        <instagram-icon
          style="width: 25px; height: 30px"
          class="cursor-pointer"
          @click="openInstagram"
        />
      </v-row>
      <v-list dense dark nav flat>
        <v-list-item
          v-for="m in loadMenu"
          :id="m.meta.id"
          :key="m.name"
          :to="{ name: m.name }"
          class="menu-item py-3"
          active-class="white secondary--text"
        >
          <v-list-item-content>
            <v-list-item-icon class="justify-center align-center">
              <v-icon>{{ m.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="menu-text font-weight-bold">
              {{ $t(m.meta.text) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!--      </v-slide-x-transition>-->
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import menu from "@/router/menu";
import WhiteLogo from "@/components/svg/WhiteLogo";
export default {
  components: {
    WhiteLogo,
    FacebookIcon: () => import("@/components/svg/Facebook"),
    InstagramIcon: () => import("@/components/svg/Instagram"),
    YoutubeIcon: () => import("@/components/svg/Youtube"),
  },
  data: () => ({}),

  computed: {
    isAcceptedTerms() {
      return this.$store.getters["login/termsAndPrivacyAccepted"];
    },
    isLogged() {
      return this.$store.getters["login/isLogged"];
    },
    isTherapist() {
      return this.$store.getters["login/isTherapist"];
    },
    loadMenu() {
      const selectedRole = this.$store.getters["login/activeRole"];

      return menu.filter((m) => {
        return m.visible && (!m.meta.role || m.meta.role === selectedRole);
      });
    },
    roles() {
      return this.$store.getters["login/roles"];
    },

    userName() {
      return this.$store.getters["login/userName"];
    },
    popoverPosition() {
      return window.innerWidth <= 600 ? "bottom" : "right";
    },
    drawer: {
      set(value) {
        this.$store.commit("settings/setDrawer", value);
      },
      get() {
        const isAcceptedTerms =
          this.$store.getters["login/termsAndPrivacyAccepted"];
        //    return this.$store.state.settings.drawer;
        return this.isLogged && isAcceptedTerms;
      },
    },
  },
  watch: {
    drawer() {
      this.handleOnboarding();
    },
  },
  mounted() {
    this.handleOnboarding();
  },
  methods: {
    updateTherapistOnboarding() {
      const onboarding = this.$store.getters["login/onboarding"];
      this.$store.dispatch("login/updateOnboarding", {
        ...onboarding,
        sideMenuTherapist: true,
      });
    },

    updatePatientOnboarding() {
      const onboarding = this.$store.getters["login/onboarding"];
      this.$store.dispatch("login/updateOnboarding", {
        ...onboarding,
        sideMenuPatient: true,
      });
    },
    logout() {
      this.drawer = false;
      this.$store.dispatch("login/logout");
    },
    async handleOnboarding() {
      const therapistSteps = [
        {
          element: "#home",
          popover: {
            title: `Olá, ${this.userName}!`,
            description:
              "Aqui é a sua página inicial. É nela que você vai encontrar um resumo das atividades.",
            position: this.popoverPosition,
          },
        },
        {
          element: "#therapys",
          popover: {
            title: "Terapias realizadas",
            description:
              "Aqui você encontra uma lista de todas as terapias realizadas.",
            position: this.popoverPosition,
          },
        },

        {
          element: "#appointmentsTherapist",
          popover: {
            title: "Agendamentos",
            description:
              "Aqui você pode gerenciar os agendamentos das terapias. ",
            position: this.popoverPosition,
          },
        },
        {
          element: "#tutorial",
          popover: {
            title: "Tutoriais",
            description:
              "Está com alguma dúvida? Aqui você encontra tutoriais em vídeo explicando detalhadamente as funcionalidades do sistema. ",
            position: "top",
          },
        },
        {
          element: "#settings",
          popover: {
            title: "Configurações",
            description:
              "Aqui você pode preencher o formulário para se tornar um terapeuta homologado pelo Par Magnético.",
            position: "top",
          },
          onNext: () => {
            this.updateTherapistOnboarding();
          },
        },
      ];

      const patientSteps = [
        {
          element: "#home",
          popover: {
            title: `Olá, ${this.userName}!`,
            description:
              "Aqui é a sua página inicial. É nela que você vai encontrar um resumo das atividades.",
            position: "right",
          },
        },
        {
          element: "#myTherapys",
          popover: {
            title: "Minhas terapias",
            description:
              "Aqui você encontra uma lista de todas as suas terapias realizadas.",
            position: this.popoverPosition,
          },
        },

        {
          element: "#appointmentsPatient",
          popover: {
            title: "Agendamentos",
            description:
              "Aqui você pode encontrar um profissional e realizar agendamentos de terapias. ",
            position: this.popoverPosition,
          },
        },
        {
          element: "#tutorial",
          popover: {
            title: "Tutoriais",
            description:
              "Está com alguma dúvida? Aqui você encontra tutoriais em vídeo explicando detalhadamente as funcionalidades do sistema. ",
            position: "top",
          },
          onNext: () => {
            this.updatePatientOnboarding();
          },
        },
      ];
      const selectedRole = this.$store.getters["login/activeRole"];
      const onboarding = this.$store.getters["login/onboarding"];
      const steps =
        selectedRole === "therapist"
          ? therapistSteps
          : selectedRole === "patient"
          ? patientSteps
          : [];

      if (
        (selectedRole === "therapist" &&
          (!onboarding || !onboarding.sideMenuTherapist)) ||
        (selectedRole === "patient" &&
          (!onboarding || !onboarding.sideMenuPatient) &&
          this.isAcceptedTerms)
      ) {
        await this.showDriver(steps);
      }
    },
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    async newTherapy() {
      await this.$store.dispatch("therapy/newTherapy");
    },
    redirect() {
      if (this.$route.name !== "home") {
        this.$router.push({ path: "/home" });
      }
    },
    openFacebook() {
      window.open("https://www.facebook.com/app.biomagnetismo/", "_target");
    },
    openInstagram() {
      window.open("https://www.instagram.com/app.bio/", "_target");
    },
    openYoutube() {
      window.open("https://www.youtube.com/@appbio6942", "_target");
    },
    async showDriver(steps) {
      if (this.drawer) {
        setTimeout(() => {
          this.$vueJsDriver.showDriver(steps);
        }, 1000);
      }
    },
  },
};
</script>

<style scoped>
.menu-item {
  max-height: 90px;
}
</style>
